import { Helmet } from 'react-helmet-async';
// sections
import Register from '../../sections/auth/Register';

// ----------------------------------------------------------------------

export default function RegisterPage() {
  return (
    <>
      <Helmet>
        <title> Create Account | SOMOS</title>
        <meta content="Crea tu cuenta" property="og:title" />
      </Helmet>

      <Register />
    </>
  );
}
