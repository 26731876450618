import { BrowserRouter } from 'react-router-dom';

import LoggedOutNavigation from './loggedout.navigator';
import LoggedInNavigation from './loggedin.navigator';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core';
import { useEffect } from 'react';

const Navigator = () => {
  // === Handle Authentication Routing ===

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch.auth.getToken();
  // }, []);

  // const auth = useSelector((state: RootState) => state.auth);

  // return (
  //   <BrowserRouter>
  //     {auth.auth ? <LoggedInNavigation /> : <LoggedOutNavigation />}
  //   </BrowserRouter>
  // );

  // === No Authentication Routing ===
  return (
    <BrowserRouter>
      <LoggedOutNavigation />
    </BrowserRouter>
  );
};

export default Navigator;
