import { createModel } from '@rematch/core';
import { type RootModel } from '.';
import { GLOBAL } from '../../constants/variables';

export const auth = createModel<RootModel>()({
  state: {
    auth: null,
  },
  reducers: {
    storeAuth(state, payload: any) {
      return {
        ...state,
        auth: payload,
      };
    },
  },
  effects: dispatch => ({
    async getAuth(payload) {
      try {
        dispatch.auth.storeAuth(payload);
        await localStorage.setItem(GLOBAL.TOKEN_KEY, payload);
      } catch (error) {
        console.log('ERROR AQUI', error);
      }
    },
    async getToken() {
      const token = await localStorage.getItem(GLOBAL.TOKEN_KEY);
      dispatch.auth.storeAuth(token);
    },
    async logout() {
      console.log('Logging out, Goodbye');
      localStorage.removeItem(GLOBAL.TOKEN_KEY);
      dispatch.auth.storeAuth(null);

      // Reset Redux State
      localStorage.removeItem('persist:root');
    },

    async createAccount(payload) {
      console.log('createAccount', payload);
    },
  }),
});
