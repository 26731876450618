import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, type BoxProps, Typography } from '@mui/material';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
// import { PATH_PAGE } from '../../routes/paths';

// components
import Logo from '../logo';

// ----------------------------------------------------------------------

interface Props {
  isOffset: boolean;
  intercom?: string;
}

// TODO Update path to support page
export default function Header({ isOffset, intercom }: Props) {
  const [openIntercom, setOpenIntercom] = useState(false);

  const theme = useTheme();

  const launchIntercom = () => {
    if (!openIntercom) {
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'zt3ewpwd',
      });

      if (intercom) {
        Intercom('showNewMessage', intercom);
      } else {
        Intercom('show');
      }

      setOpenIntercom(true);
    } else {
      Intercom('hide');
      setOpenIntercom(false);
    }
  };

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}>
        <Logo />
        <Typography
          variant="body1"
          onClick={launchIntercom}
          sx={{
            cursor: 'pointer',
          }}>
          ¿Necesitas ayuda?
        </Typography>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: theme => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
