import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { useDispatch } from "react-redux";

import { API_URL, TOKEN, URI } from "../../constants/api";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...config,
    baseURL: URI,
    /* headers: {<
			authorization: TOKEN
		}, */
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.message === "Request failed with status code 401") {
    // localStorage.clear();
    window.location.href = "/";
  }
  return Promise.reject({
    message: error.message,
    code: error.code,
  });
};

export function SET_UP_AXIOS(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
