// @mui
import { Typography, Stack, Link } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
import Header from '../../components/header';

//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

interface Props {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
}

export default function LoginLayout({ title, children }: Props) {
  return (
    <StyledRoot>
      <Header
        isOffset={false}
        intercom="Hola, Estoy teniendo problemas al cerear mi cuenta. ¿Me pueden ayudar?"
      />

      <StyledSection>
        <Typography variant="h3" sx={{ mb: 10, maxWidth: 680, textAlign: 'center' }}>
          {title || '¡Hola, bienvenid@ al sistema de administración de pacientes!'}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={'/assets/illustrations/illustration_dashboard_somos.png'}
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}>{children}</Stack>
      </StyledContent>
    </StyledRoot>
  );
}
