import * as Yup from 'yup';
import axios, { type AxiosError } from 'axios';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, IconButton, InputAdornment, Alert, Select, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFCheckbox, RHFTextField } from '../../components/hook-form';
import { useSnackbar } from 'notistack';

// variables
import { API_URL } from '../../constants/api';
// ----------------------------------------------------------------------

interface FormValuesProps {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  nfc: boolean;
  afterSubmit?: string;
}

export default function AuthRegisterForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [openIntercom, setOpenIntercom] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Necesitamos tu nombre para crear tu cuenta'),
    lastname: Yup.string().required('Necesitamos tu apellido para crear tu cuenta'),
    email: Yup.string()
      .trim()
      .email('Ingresa un correo valido')
      .required('Necesitamos tu correo para crear tu cuenta'),
    phone: Yup.number()
      .typeError('Tu télefono solo puede tener numeros')
      .test(
        'is-ten-digits',
        'Tu télefono debe tener 10 digitos',
        value => String(value).length === 10,
      ),
    nfc: Yup.boolean().oneOf(
      [true],
      'Tu teléfono debe de tener NFC para poder utilizar la app de Somos',
    ),
  });

  const defaultValues = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    nfc: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const launchIntercom = () => {
    if (!openIntercom) {
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'zt3ewpwd',
      });

      Intercom(
        'showNewMessage',
        'Hola, Estoy teniendo problemas al cerear mi cuenta. ¿Me pueden ayudar?',
      );

      setOpenIntercom(true);
    } else {
      Intercom('hide');
      setOpenIntercom(false);
    }
  };

  const onSubmit = async (data: FormValuesProps) => {
    const formData = {
      name: data.name.trim(),
      lastname: data.lastname.trim(),
      email: data.email.toLowerCase().trim(),
      phone: data.phone.toString().trim(),
    };

    try {
      const response = await axios.post(`${API_URL}users`, formData);

      const userId = response.data.userId;

      enqueueSnackbar('Tu cuenta ha sido creada');

      window.analytics.identify(userId, formData);

      reset();

      window.location.href = 'https://www.somos.me/account-success';
    } catch (error: any) {
      console.info('error info', error.status);
      console.log(error.response);
      console.log(error.requeset);
      console.log(error.message);
      console.log(error.config);

      let errorMessage = error.response?.data.message || error.message;

      if (
        errorMessage === 'Request failed with status code 409' ||
        errorMessage === 'Request failed with status code 400'
      ) {
        errorMessage = 'Ya existe un usuario con este correo';
      } else {
        errorMessage = 'Ocurrio un error, intenta de nuevo';
        launchIntercom();
      }

      console.log('error', errorMessage);

      // TODO: Handle error
      setError('afterSubmit', {
        message: errorMessage,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="name" label="Nombre" data-cy="name" />
        <RHFTextField name="lastname" label="Apellido" data-cy="last-name" />
        <RHFTextField name="email" label="Correo electronico" data-cy="email" />
        <RHFTextField
          name="phone"
          label="Teléfono"
          placeholder="A 10 dígitos"
          data-cy="phone-number"
        />
        <Stack spacing={0.5}>
          <RHFCheckbox
            name="nfc"
            label="Confirmo que mi teléfono tiene NFC"
            sx={{ textAlign: 'center' }}
            data-cy="nfc-checkbox"
          />
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            ¿No sabes si tiene NFC? Busca tu teléfono{' '}
            <Link
              href="https://www.nfcw.com/nfc-phones-list/"
              target="_blank"
              rel="noopener"
              color="inherit"
              underline="always">
              en esta base de datos
            </Link>
          </Typography>
        </Stack>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            color: theme => theme.palette.primary.contrastText,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
              color: theme => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}
          data-cy="submit">
          Crear cuenta
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
