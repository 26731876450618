import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, type BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 256 256"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M255.897 142.104H188.177C147.161 142.104 113.793 108.735 113.793 67.718V0H142.001V67.718C142.001 93.1808 162.714 113.895 188.177 113.895H255.897V142.104Z"
            fill="#00AC69"
          />
          <path
            d="M142.102 256H113.895V188.282C113.895 162.819 93.1808 142.105 67.718 142.105H0V113.896H67.718C108.735 113.896 142.102 147.265 142.102 188.282V256Z"
            fill="#00AC69"
          />
          <path
            d="M175.15 175.198C177.928 172.42 181.695 170.859 185.624 170.859C189.552 170.859 193.32 172.42 196.098 175.198L241.546 220.646L220.593 241.598L175.146 196.15C173.77 194.775 172.678 193.141 171.934 191.344C171.19 189.546 170.807 187.619 170.807 185.673C170.807 183.728 171.191 181.801 171.936 180.004C172.681 178.206 173.773 176.573 175.15 175.198Z"
            fill="#00AC69"
          />
          <path
            d="M14.3574 35.3516L35.3098 14.3991L80.7574 59.8467C82.1329 61.2222 83.224 62.8551 83.9684 64.6523C84.7128 66.4494 85.0959 68.3756 85.0959 70.3209C85.0959 72.2661 84.7128 74.1923 83.9684 75.9895C83.224 77.7866 82.1329 79.4196 80.7574 80.7951C79.3819 82.1706 77.749 83.2616 75.9518 84.0061C74.1546 84.7505 72.2284 85.1336 70.2832 85.1336C68.338 85.1336 66.4118 84.7505 64.6146 84.006C62.8174 83.2616 61.1845 82.1705 59.809 80.795L14.3615 35.3475L14.3574 35.3516Z"
            fill="#00AC69"
          />
          <path
            d="M175.146 80.8018C173.771 79.4263 172.68 77.7934 171.935 75.9962C171.191 74.199 170.808 72.2728 170.808 70.3276C170.808 68.3824 171.191 66.4562 171.935 64.659C172.68 62.8618 173.771 61.2289 175.146 59.8534L220.594 14.4059L241.546 35.3583L196.099 80.8058C194.723 82.1813 193.09 83.2724 191.293 84.0168C189.496 84.7612 187.57 85.1444 185.625 85.1444C183.679 85.1444 181.753 84.7613 179.956 84.0168C178.159 83.2724 176.526 82.1813 175.15 80.8058L175.146 80.8018Z"
            fill="#00AC69"
          />
          <path
            d="M35.3005 241.596L14.3481 220.644L59.7956 175.196C62.5736 172.418 66.3412 170.858 70.2698 170.858C74.1984 170.858 77.9661 172.418 80.744 175.196C83.5219 177.974 85.0826 181.742 85.0826 185.67C85.0826 189.599 83.522 193.367 80.744 196.144L35.2965 241.592L35.3005 241.596Z"
            fill="#00AC69"
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="https://somos.me" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
