import { createModel } from '@rematch/core';
import { type RootModel } from '.';

// Axios
import axios from 'axios';
import { API_URL } from '../../constants/api';

export const user = createModel<RootModel>()({
  state: {
    user: {
      id: '',
      name: '',
      email: '',
      phone: '',
      createdAt: '',
      status: '',
      photoURL: '',
      weight: '',
      genere: '',
    },
    settings: {
      filter: {
        type: 'days',
        days: 1,
        date: {
        start: '', // or Date, or another type
        end: '', // or Date, or another type
      },
      },
    },
  },
  reducers: {
    storeUserInfo(state, payload: any) {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    },
    storeProfileInfo(state, payload: any) {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    },
    storeSettingsInfo(state, payload: any) {
      return {
        ...state,
        settings: {
          ...state.settings,
          filter: payload,
        },
      };
    },
  },
  effects: dispatch => ({
    async getUserInfo(payload) {
      dispatch.user.storeUserInfo(payload);
    },
    async getProfileInfo(userId, state) {
      axios
        .get(`${API_URL}users/${userId}`, {
          headers: { Authorization: `Bearer ${state.auth.auth}` },
        })
        .then(res => {
          dispatch.user.storeProfileInfo(res.data);
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    getSettingsInfo(payload) {
      dispatch.user.storeSettingsInfo(payload);
    },
  }),
});
