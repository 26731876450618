import { Routes, Route } from 'react-router-dom';

// Views
import RegisterPage from '../views/auth/RegisterPage';

// Layouts

// Routes if user is logged out.
const LogOutNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<RegisterPage />} />
    </Routes>
  );
};

export default LogOutNavigation;
