import { Link as RouterLink } from 'react-router-dom';

// @mui
import { Stack, Typography, Link } from '@mui/material';
// layouts
import LoginLayout from '../../views/login';
// routes
import { PATH_AUTH } from '../../routes/paths';

// Components
import AuthRegisterForm from './AuthRegisterForm';
import Header from 'components/header/Header';

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <LoginLayout title="Crea tu cuenta y escucha lo que tu cuerpo tiene que decir">
      <Stack spacing={1} sx={{ mb: 3, position: 'relative' }}>
        <Typography variant="h4" sx={{ width: '90%' }}>
          Escucha lo que tu cuerpo tiene que decir.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Crea tu cuenta
        </Typography>
      </Stack>

      <AuthRegisterForm />

      <Typography
        component="div"
        sx={{ color: 'text.secondary', mt: 3, typography: 'caption', textAlign: 'center' }}>
        {'Al crear mi cuenta estoy de acuerdo con los '}
        <Link
          underline="always"
          color="text.primary"
          href="https://www.somos.me/terminos-y-condiciones"
          target="_blank">
          Términos y condiciones
        </Link>
        {' y la '}
        <Link
          underline="always"
          color="text.primary"
          href="https://www.somos.me/politica-de-privacidad"
          target="_blank">
          Política de privacidad
        </Link>
        .
      </Typography>
    </LoginLayout>
  );
}
