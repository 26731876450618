import React from 'react';

import { HelmetProvider } from 'react-helmet-async';

// State Management
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './core';

// Styles
import './index.css';
import './App.css';

// Theme
import ThemeProvider from 'theme';

// Routes
import Routes from './routes';

// Authentication
import { STYTCH_PUBLIC_TOKEN } from './constants/variables';
import { StytchUIClient } from '@stytch/vanilla-js';
import { StytchProvider } from '@stytch/react';

// Components
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from 'components/snackbar/SnackbarProvider';

const stytch = new StytchUIClient(STYTCH_PUBLIC_TOKEN as string);

export default function App() {
  return (
    <SettingsProvider>
      <ThemeProvider>
        <ThemeSettings>
          <ReduxProvider store={store}>
            <StytchProvider stytch={stytch}>
              <HelmetProvider>
                <SnackbarProvider>
                  <Routes />
                </SnackbarProvider>
              </HelmetProvider>
            </StytchProvider>
          </ReduxProvider>
        </ThemeSettings>
      </ThemeProvider>
    </SettingsProvider>
  );
}
