// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  if (sublink.startsWith('/')) {
    sublink = sublink.substring(1);
  }
  return `${root}${sublink}`;
}

// ----------------------------------------------------------------------

const ROOTS_DASHBOARD = '/';

export const PATH_HOME = {
  root: '/',
};

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
};

export const PATH_PAGE = {
  faqs: '/faqs',
};

// TODO: Set right paths later
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  users: path(ROOTS_DASHBOARD, 'dashboard'),
  reports: path(ROOTS_DASHBOARD, 'reports'),
  activity: path(ROOTS_DASHBOARD, 'activity'),
  recommendations: {
    root: path(ROOTS_DASHBOARD, 'recommendations'),
    new: path(ROOTS_DASHBOARD, '/recommendations/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `recommendations/${id}/edit`),
  },
  ranges: {
    root: path(ROOTS_DASHBOARD, 'ranges'),
    new: path(ROOTS_DASHBOARD, '/ranges/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `ranges/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, 'dashboard'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, 'profile'),
    general: path(ROOTS_DASHBOARD, '/general'),
  },
};
