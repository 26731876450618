import { createModel } from '@rematch/core';
import { type RootModel } from '.';

// TODO: Update account model to user_admin schema.

export const account = createModel<RootModel>()({
  state: {
    user: {
      id: '',
      email: '',
      name: '',
      lastName: '',
      photoURL: 'https://res.cloudinary.com/siempresomos/image/upload/v1683228294/defaulUser.png',
      phoneNumber: '',
      country: '',
      address: '',
      state: '',
      city: '',
      zipCode: '',
      about: '',
      organization: '',
    },
  },
  reducers: {
    storeAccountInfo(state, payload: any) {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    },
  },
  effects: dispatch => ({
    async getAccountInfo(payload) {
      dispatch.account.storeAccountInfo(payload);
    },
  }),
});
